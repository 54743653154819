exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-icon-redirection-js": () => import("./../../../src/pages/icon-redirection.js" /* webpackChunkName: "component---src-pages-icon-redirection-js" */),
  "component---src-pages-in-lcl-consol-js": () => import("./../../../src/pages/in/lcl-consol.js" /* webpackChunkName: "component---src-pages-in-lcl-consol-js" */),
  "component---src-pages-in-pay-later-js": () => import("./../../../src/pages/in/pay-later.js" /* webpackChunkName: "component---src-pages-in-pay-later-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-user-agreement-js": () => import("./../../../src/pages/user-agreement.js" /* webpackChunkName: "component---src-pages-user-agreement-js" */),
  "component---src-templates-dynamic-landing-page-template-js": () => import("./../../../src/templates/dynamic-landing-page/Template.js" /* webpackChunkName: "component---src-templates-dynamic-landing-page-template-js" */),
  "component---src-templates-glossary-term-page-template-js": () => import("./../../../src/templates/glossary-term-page/Template.js" /* webpackChunkName: "component---src-templates-glossary-term-page-template-js" */),
  "component---src-templates-knowledge-series-page-template-js": () => import("./../../../src/templates/knowledge-series-page/Template.js" /* webpackChunkName: "component---src-templates-knowledge-series-page-template-js" */),
  "component---src-templates-strapi-page-template-js": () => import("./../../../src/templates/strapi-page/Template.js" /* webpackChunkName: "component---src-templates-strapi-page-template-js" */)
}

