import sbjs from 'sourcebuster';

export const onInitialClientRender = (_, options) => {
  const { domain } = options;

  sbjs.init({
    domain: {
      host: domain,
      isolate: false,
    },
    lifetime: 12,
    timezone_offset: '0',
    referrals: [
      { host: 't.co', medium: 'social', display: 'twitter.com' },
      { host: 'm.facebook.com', medium: 'social', display: 'facebook.com' },
      { host: 'l.facebook.com', medium: 'social', display: 'facebook.com' },
      { host: 'lm.facebook.com', medium: 'social', display: 'facebook.com' },
      { host: 'facebook.com', medium: 'social' },
      { host: 'linkedin.com', medium: 'social' },
      { host: 'instagram.com', medium: 'social' },
      { host: 'l.instagram.com', medium: 'social', display: 'instagram.com' },
      { host: 'm.instagram.com', medium: 'social', display: 'instagram.com' },
      { host: 'lm.instagram.com', medium: 'social', display: 'instagram.com' },
    ],
  });
};
