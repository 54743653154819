exports.DEFAULT_OPTIONS = {
  maxWidth: 720,
  wrapperStyle: ``,
  backgroundColor: `white`,
  linkImagesToOriginal: false,
  showCaptions: false,
  markdownCaptions: false,
  withWebp: true,
  tracedSVG: false,
  loading: `lazy`,
  disableBgImageOnAlpha: false,
  disableBgImage: false,
};

exports.imageClass = 'gatsby-resp-image-image';
exports.figureClass = 'gatsby-resp-image-figure';
exports.pictureClass = 'gatsby-resp-image-picture';
exports.anchorLinkClass = 'gatsby-resp-image-link';
exports.imageWrapperClass = 'gatsby-resp-image-wrapper';
exports.figCaptionClass = 'gatsby-resp-image-figcaption';
exports.imageBackgroundClass = 'gatsby-resp-image-background-image';
