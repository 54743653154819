module.exports = [{
      plugin: require('../plugins/sf-marketing-remark-remote-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":100},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shipa Freight","short_name":"Shipa","start_url":"/","background_color":"#FFA38B","theme_color":"#FFA38B","display":"minimal-ui","icon":"src/assets/images/shipa-freight-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c290d04d5c56b58eda5f869cc5e93752"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToStylesProvider":"src/infra/styles/muiStylesProvider","pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../plugins/sf-marketing-pardot/gatsby-browser.js'),
      options: {"plugins":[],"delay":5000,"piAId":"428912","piCId":"101897","piHostname":"pi.pardot.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":100},
    },{
      plugin: require('../plugins/sf-marketing-sourcebuster/gatsby-browser.js'),
      options: {"plugins":[],"domain":"shipafreight.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
